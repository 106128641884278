<template>
  <transition name="modal-fade">
    <div class="modal-overlay" v-if="isVisible" @click.self="close">
      <div class="modal">
        <div class="modal-content">
          <div class="title">
            <div class="title-text"></div>
            <div>
              <Toggle
                v-if="!isAbout"
                v-model="viewMode" 
                value1='map'
                value2='list'
              />
              <div v-if="showJourneySwitching && false" class="navigation-buttons"><!-- disabled for now -->
                <button :class="['custom-button button-balkans', colorClass]">Balkans</button>
                <button :class="['custom-button button-asia', colorClass]">Asia</button>
              </div>
            </div>
          </div>

          <div class="subtitle">
            <hr class="subtitle-divider">
            <div class="subtitle-text">{{ isAbout? 'About' : viewMode }}</div>
            <hr class="subtitle-divider">
          </div>
          
          <transition v-if="!isAbout" name="view-switch" mode="out-in">
            <div :key="viewMode">
              <div v-if="viewMode==='map'">
                <Map @go-to-post="goToPost" />
              </div>

              <div v-else-if="viewMode==='list'">
                <List @go-to-post="goToPost" />
              </div>
              </div>
          </transition>

          <div v-else>
            <About />
          </div>

        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Toggle from './Toggle.vue'
import Map from './Map.vue'
import List from './List.vue'
import About from './About.vue'

export default {
  name: 'ModalNavigation',
  components: {
    Toggle,
    Map,
    List,
    About
  },
  props: {
    isVisible: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    showJourneySwitching () {
      return ['map', 'list'].includes(this.viewMode)
    },
    colorClass () {
      return this.$store.getters.colorClass
    },
    posts () {
      return this.$store.state.posts
    },
    selectedPostIndex () {
      return this.$store.state.selectedPostIndex
    },
    isAbout () {
      return this.$store.state.isAbout
    },
    viewMode: {
      get () {
        return this.$store.state.viewMode
      },
      set (newViewMode) {
        this.$store.commit('SET_VIEW_MODE', newViewMode)
      }
    }
  },
  methods: {
    close () {
      this.$store.commit('SET_IS_ABOUT', false)
      this.$emit('close')
    },
    goToPost (index) {
      this.$store.dispatch('goToPost', index)
      this.close()
    }
  },
  mounted () {
    const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0
    
    if (isTouchDevice) {
      this.$store.commit('SET_VIEW_MODE', 'list')
    }
  }
}
</script>

<style scoped lang="scss">
@import '../main.scss';

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(25, 25, 25, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  transition: opacity 0.5s ease;
}

.modal {
  position: relative;
  background-color: $black;
  border-radius: 14px;
  width: 60vw;
  transform: scale(0.9);
  opacity: 0;
  box-shadow: 10px 10px 20px 0px rgba(0,0,0,0.5);
  transition: transform 0.5s ease, opacity 0.5s ease;
  overflow: hidden;

  @media only screen and (max-width: 1000px) {
    width: 80vw;
  }
  @media only screen and (max-width: 500px) {
    width: 95vw;
  }
}

.modal-content {
  padding: 30px 50px;

  @media only screen and (max-width: 1000px) {
    padding: 20px 10px;
  }
  @media only screen and (max-width: 500px) {
    padding: 10px 6px;
  }
}

/*  title  */
.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'RozhaOne';
  color: white;
  padding: 10px 20px;
  height: 35px;

  @media only screen and (max-width: 1000px) {
    margin-bottom: 40px;
  }
  @media only screen and (max-width: 500px) {
    margin-bottom: 8px;
  }

  & .title-text,
  & .navigation-buttons > .custom-button {
    animation: titleAppearFromTop 0.4s ease-in-out forwards;
  }

  & .navigation-buttons > .button-asia {
    animation-delay: 200ms;
  }
}

@keyframes titleAppearFromTop {
  0% {
    transform: translateY(-15px);
  }
  100% {
    transform: translateY(0px);
  }
}

.title-text {
  font-size: 25px;
  transform: translateY(-15px);

  @media only screen and (max-width: 1000px) {
    font-size: 20px;
  }
  @media only screen and (max-width: 500px) {
    font-size: 15px;
  }
}

.navigation-buttons {
  display: flex;
  gap: 10px;
  cursor: pointer;

  @media only screen and (max-width: 500px) {
    gap: 5px;
  }
}

.custom-button {
  background-color: transparent;
  color: white;
  border: 2px solid white;
  border-radius: 15px;
  padding: 5px 5px;
  cursor: pointer;
  font-family: 'RozhaOne';
  font-size: 15px;
  width: 100px;
  height: 35x;
  text-align: center;
  transition: all 0.6s ease;
  transform: translateY(-15px);

  &:hover {
    &.green { color: $green-light; border: 2px solid $green-light; }
    &.blue { color: $blue-light; border: 2px solid $blue-light; }
    transition: color 0.6s ease, border 0.6 ease;
  }

  &:active {
    &.green { color: $green-light; border: 2px solid $green-light; }
    &.blue { color: $blue-light; border: 2px solid $blue-light; }
    transition: color 0.6s ease, border 0.6 ease;
  }

  @media only screen and (max-width: 1000px) {
    width: 80px;
  }

  @media only screen and (max-width: 500px) {
    font-size: 14px;
    width: 60px;
    padding: 5px 5px;
  }
}

.subtitle {
  display: flex;
  align-items: center;
  font-family: 'RozhaOne';
  color: white;
  margin-bottom: 30px;

  @media only screen and (max-width: 1000px) {
    padding: 0 10px;
    margin-bottom: 10px;
  }
}

.subtitle-text {
  width: 45px;
  font-size: 20px;
  margin-right: 20px;
  margin-left: 20px;
  text-align: center;

  @media only screen and (max-width: 500px) {
    width: 30px;
    font-size: 14px;
  }
}

.subtitle-divider {
  flex-grow: 1;
  border: 1px solid white;;
}

.view-switch-enter-active, .view-switch-leave-active {
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.view-switch-enter {
  opacity: 0;
  transform: translateY(10px);
}

.view-switch-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}

.modal-fade-enter-from,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-to,
.modal-fade-leave-from {
  opacity: 1;
}

.modal-overlay .modal {
  opacity: 1;
  transform: scale(1);
}

.modal-fade-enter-active .modal,
.modal-fade-leave-active .modal {
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.modal-fade-enter-from .modal,
.modal-fade-leave-to .modal {
  opacity: 0;
  transform: scale(0.9);
}

.modal-fade-enter-to .modal,
.modal-fade-leave-from .modal {
  opacity: 1;
  transform: scale(1);
}
</style>

import { PostProps } from "@/types"

const post15: PostProps = {
  key: 15,
  journeyKey: 1,
  x: '26%',
  y: '18%',
  zIndex: 110,
  location: 'Siem Reap',
  title: 'Fixing the laptop and exploring the numbingly hot - but beautiful town',
  content: [
    {
      type: 'text',
      value: [
        `It is Tuesday morning. Claudia and I are having breakfast at the hostel’s rooftop and we still gasp at the architecture. The rooftop has a bar, a swimming pool, and beautiful couch-like spots for sitting.`,
        `Claudia goes exploring, but my biggest concern is fixing my laptop. I mark maybe 20 places on the map that seem like they have something to do with laptops. If it is really necessary, I am ready to visit all of them. I am all geared up and ready to fight, even if it takes all day, even if I need to sacrifice the day after tomorrow too. I do not want travel to hinder my job. I need this lifestyle to work!`,
        `I am now walking out of the beautiful, grandiose gate of the hostel, turning left, and I want to laugh at the first thing I see. A giant, giant, bright-green sign with the Acer logo. And underneath, a door into an electronics store. I walk in and immediately show my charger. I pray while the shop assistant searches the stock. He comes back with a charger in his hands, plugs it in and I see the little red light on my laptop go on.`,
        `It is a huge relief. I almost want to laugh, or cry, I am not sure. I can enjoy Siem Reap without any trouble now. I will not lose any more work days. I was ready for the worst but got the best.`,
        `This whole experience is making me even more appreciative of my work and what it allows me to do.`,
        `The next stop is a sim card store nearby. There, I encounter Claudia again. At this point, we are used to our paths crossing. ‘My laptop is working! I bought a working charger!’ I exclaim and she celebrates this win with me. We both get our sim cards and try to see whether the other one wants to hang out too. Until now, we have never forced anything.`,
        `We are now strolling through the hot town. I think this is the hottest it has been since I arrived in Asia. It might be around 38 degrees, but Siem Reap specifically feels heavy. I have never sweated this much.`,
        `There are many gorgeous hotels, cafes and restaurants. At every corner, there are tuktuk drivers shouting at everyone. ‘I need a shirt that says no tuk-tuk because I don’t have energy to refuse them anymore,’ I tell Claudia.`,
        `Claudia finds a really cool gallery. The artist Christian Develter currently has an exhibition there. In his paintings, he is depicting mostly women from the Chin tribe in Myanmar. These women have used tattoo marks on their faces for centuries. With these marks, they can distinguish one hill tribe from another or even indicate marital status. It looks really beautiful but there is quite a bitter legend connected to it. According to the story, this is how women protected themselves from being kidnapped and forced into marriage by Burmese kings. I have been fascinated by Asian-related art and this is now the peak of my experience.`,
      ]
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/01_southeast_asia/post15/1.jpg',
          description: ['the tattoo marks in this art look really ethereal'],
        },
        {
          src: '/images/01_southeast_asia/post15/2.jpg',
          description: ['there is also the connection with the butterly concept'],
        },
        {
          src: '/images/01_southeast_asia/post15/3.jpg',
          description: ['how it can look in real life'],
        },
        {
          src: '/images/01_southeast_asia/post15/4.jpg',
          description: ['another example of a woman with facial tattoo marks'],
        },
      ],
    },
    {
      type: 'text',
      value: [
        `The next stop is a gorgeous temple - Wat Bo. It is wonderfully orange - it makes me feel it is very Cambodian. Especially the color. The temples in Thailand had a bit less intense colors, the ones in Laos had a bit less ornaments and seemed more communist/functionalist. This temple just feels really extra mysterious and exotic to me.`,
        `Finally, we end up in a wonderful giant cafe with an industrial vibe. Claudia made up this plan for today before she wanted to go exploring and it shows. She is a real architecture lover.`,
        `Here, we dive into more wonderful personal conversations. More goosebumps. More answered questions, for me but for her too. It is totally clear to me now why we met. Claudia and her impact will always stay with me and I do not need to add anything else.`
      ]
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/01_southeast_asia/post15/5.jpg',
          description: ['Wat Bo with its nice colors'],
        },
        {
          src: '/images/01_southeast_asia/post15/6.jpg',
          description: ['exotic gardens, golden statues'],
        },
        {
          src: '/images/01_southeast_asia/post15/7.jpg',
          description: ['the amazing cafe we found'],
        },
        {
          src: '/images/01_southeast_asia/post15/8.jpg',
          description: ['and lovely Claudia enjoying her italian lunch'],
        },
      ],
    },
    {
      type: 'text',
      value: [
        `On Wednesday, we borrow bicycles and bike around the town. Finally some wind to cool off in this crazy humid and hot weather. We visit the market and look around for some nice gifts. I buy most of my gifts here. And funnily enough, a shirt that says ‘no tuk-tuk’ actually exists! We laugh. I do not want to miss this chance and buy it for a low price. And believe it or not, I actually have a feeling the shirt does work a little - but not completely, since the drivers shout long before they would even notice what clothes you are wearing.`,
        `Yesterday, we found a gallery with paintings; today it’s photography. I am especially impressed by the old camera collection. After a good lunch with Claudia, I go back to the hostel to get some work done.`,
        `Feeling refreshed after my shower, I walk through the hall and spot Diana, the Peruvian woman I met in Chiang Rai. We gasp and just stare at each other in disbelief. Once we process it, we run into a hug. I am really excited that this awesome woman is also here now. In the evening, we meet up in the rooftop bar and have a nice productive session together accompanied by some mocktails.`
      ]
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/01_southeast_asia/post15/9.jpg',
          description: ['apart from a lot of black-and-white photography, we were amazed by the camera collection'],
        },
        {
          src: '/images/01_southeast_asia/post15/10.jpg',
          description: ['some of which were really really retro'],
        },
      ],
    },
    {
      type: 'text',
      value: [
        `On Thursday, Claudia goes to Angkor Wat for a sunrise (very very early). I adore her for it but I choose to do it on another day, where I wake up on a normal lazy-person hour. Instead, I enjoy a day full of errands and self-care. First, acupuncture with a Chinese doctor for my back pain.`,
        `This is my first time receiving acupuncture. The doctor is an elderly, adorable Chinese woman. Her assistants are Cambodian but also speak English and Chinese and translate every sentence said between me and the doctor. The doctor asks about my pain, examines the affected areas, and then lays me down in another room. There is a pleasant aroma and soft, meditative music.`,
        `They start putting needles in and around my back. It is not really that uncomfortable as I thought. Quite the opposite - after a while, I barely feel the needles. I do, however, feel their effect and that really surprises me. It feels like the pain starts travelling around, changing shape. Some muscles (or perhaps other tissues) feel like they are being detangled. Is this possible?`,
        `After my session, the doctor asks me what I was feeling. I explain the feeling of the travelling pain. ‘That is the wind trapped in your body that needs to be released,’ the doctor explains the concept.`,
        `Later on I looked up some studies which point to fascia being hardened when a certain part of the body is not moved enough. Apparently, something as simple as the needle can disrupt the fascia and bring some life to it. I am dedicated to trying the recommended amount of 3 sessions.`,
        `Next, I am picking up clothes from the tailor - while adventuring, I managed to destroy quite some important pieces I had and really needed to repair them.`,
        `Next, I find myself in a chair of a piercing studio. The moment my ear is pierced, a strange euphoria flows through me. Maybe it is the fact that I am doing something small to express myself. Something to make me feel more like myself. Shopping for some clothes afterwards makes this feeling linger around. I make sure to also buy electrolytes - since I am losing so much salt from sweating - I never knew I could sweat this much.`,
        `After that, I return to the same market I discovered with Claudia and plan to get some jewelry. The lady has really really good merchant techniques and convinces me to buy a lot of rings and a necklace. Walking back home, I feel guilty about how much I bought, but I remind myself that I have many women in my life who might appreciate jewelry as a gift.`,
        `After another wonderful lunch with Claudia, where I show off my piercing and she shares her sunrise pictures, I get back to work. What an awesome day again!`
      ]
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/01_southeast_asia/post15/11.jpg',
          description: ['our dinner spot at the market'],
        },
        {
          src: '/images/01_southeast_asia/post15/12.jpg',
          description: ['how asphault road disappears into a dust road'],
        },
        {
          src: '/images/01_southeast_asia/post15/13.jpg',
          description: ['the lobby of the beautiful hostel'],
        },
        {
          src: '/images/01_southeast_asia/post15/14.jpg',
          description: ['the hostel rooftop'],
        },
      ],
    },
  ],
  signature: '26/03/2024 - 28/03/2024'
}

export default post15

import { PostProps } from "@/types"

const post20: PostProps = {
  key: 20,
  journeyKey: 1,
  x: '26%',
  y: '20%',
  zIndex: 110,
  location: 'Chanthaburi',
  title: 'Gemstones market, cute canals, asian-christian cathedral and more temple visits',
  content: [
    {
      type: 'text',
      value: [
        `I am on the bus to Chanthaburi. In my mind, that would mean I get dropped off at a bus station, or somewhere in the center. But the bus driver seems to be using a bypass road around the town. I watch the little blue dot on google maps attentively. We just missed the last opportunity to turn towards the town!`,
        `I ask the bus driver whether he would stop in Chanthaburi. I am almost convinced he forgot. ‘Sure!’ he says and within one minute, he drops me off on the side of the road. The only taxi I can see is a guy on a motorbike. Thankfully, he is eager to help - even with my giant luggage!`,
        `The hostel is called Chernchan and at the moment, it is pretty much the only hostel one can find in Chanthaburi. As I am to find out, the town itself is very small, and so far still not too touristy.`,
        `Monday afternoon I dedicate to work. On Tuesday morning, I get to the church. It is strange to see a church here. The church - and all the surrounding Christian facilities - were built by a Vietnamese community when they were banished from their country. The architecture here is, therefore, a bit different than what I am used to. It has a Vietnamese touch to it. I am lucky to also get a glimpse of the inside of the cathedral since there is currently a sermon. I find it interesting - seeing Thai people pray and sing with the organ. Until now, I always only saw them meditate at the Buddhist temples. I try not to bother much though, as I realize this might be a funeral.`,
      ]
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/01_southeast_asia/post20/1.jpg',
          description: ['the hostel from the outside', 'they really do love plants'],
        },
        {
          src: '/images/01_southeast_asia/post20/2.jpg',
          description: ['the hostel terrace upstairs'],
        },
        {
          src: '/images/01_southeast_asia/post20/3.jpg',
          description: ['on the way towards the cathedral'],
        },
        {
          src: '/images/01_southeast_asia/post20/4.jpg',
          description: ['one of the canals on the way'],
        },
        {
          src: '/images/01_southeast_asia/post20/5.jpg',
          description: ['the strange, beautiful asian cathedral'],
        },
        {
          src: '/images/01_southeast_asia/post20/6.jpg',
          description: ['the interior of the cathedral'],
        },
      ]
    },
    {
      type: 'text',
      value: [
        `The hostel is a perfect place to work. Downstairs, there is a cafe. Upstairs, there is a terrace with big beanbags and cushions - I move places depending on how much comfort I need at the moment. My room is also quite pleasant. It is rather modern, and the bathrooms are really clean and smell nice. The bed is big and the sheets are comfortable. For the first night, I am alone in the room. I do not hesitate to do a bit of yoga here.`,
        `On Wednesday, I find some Buddhist temples. I am amazed by a really giant golden statue of Buddha, and by symbols on the feet of a reclining Buddha. To visit one of the nicest temples in the complex, I have to ignore my fear of a barking dog. Test whether a barking dog indeed does not bite. It is scary, but the dog ends up being harmless.`,
      ]
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/01_southeast_asia/post20/7.jpg',
          description: ['the charming architecture close to the cathedral'],
        },
        {
          src: '/images/01_southeast_asia/post20/8.jpg',
          description: ['cute little streets at the gemstone market', 'notice the church in the back?'],
        },
        {
          src: '/images/01_southeast_asia/post20/9.jpg',
          description: ['the golden Buddha in the sky'],
        },
        {
          src: '/images/01_southeast_asia/post20/10.jpg',
          description: ['the reclining Buddha'],
        },
      ]
    },
    {
      type: 'text',
      value: [
        `In the afternoon, I walk a bit towards the church again. This time, I want to check out the charming gem market. This place is full of cute cafes and restaurants, and the architecture feels a bit different. Perhaps also an influence of another culture. Every now and then, I see a garage and in front of it, a merchant offering a variety of beautiful gemstones. The green ones make a big impression on me.`,
        `I arrive at one of the canals. This is a really charming and romantic place. I have an idea to work at one of the cafes overlooking the canal and the church. It has plenty of art inside too. But the weather gets unbearably hot. I find it hard to focus. After my lunch, I pack up and move to the hostel, where I finish the workday with a cool head.`
      ]
    },
  ],
  signature: '22/04/2024 - 24/04/2024'
}

export default post20

<template>
  <div class="toggle">
    <span :class="[colorClass, {'active': modelValue === value1 }]">{{ value1 }}</span>
    <label class="toggle-switch">
      <input type="checkbox" :checked="modelValue === value2" @change="toggleValue">
      <span class="slider" :class="[colorClass]"></span>
    </label>
    <span :class="[colorClass, {'active': modelValue === value2 }]">{{ value2 }}</span>
  </div>
</template>

<script>
export default {
  name: 'Toggle',
  props: {
    modelValue: {
      type: String,
      required: true
    },
    value1: {
      type: String,
      required: true
    },
    value2: {
      type: String,
      required: true
    }
  },
  computed: {
    colorClass () {
      return this.$store.getters.colorClass
    }
  },
  methods: {
    toggleValue () {
      this.$emit('update:modelValue', this.modelValue === this.value1 ? this.value2 : this.value1)
    },
  }
}
</script>

<style scoped lang="scss">
.toggle {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  color: white;
}

.toggle span {
  transition: color 0.3s ease;
}

.toggle-switch {
  position: relative;
  width: 45px;
  height: 22px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $cappuccino-dark;
  border-radius: 22px;
  cursor: pointer;
  transition: 0.4s;
}

.slider:before {
  content: "";
  position: absolute;
  height: 18px;
  width: 18px;
  left: 2px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  transition: 0.4s;
}

.slider.green:before {
  background-color: $green-light;
}

.slider.blue:before {
  background-color: $blue-light;
}

input:checked + .slider:before {
  transform: translate(21px, -50%);
}

@media (max-width: 500px) {
  .toggle {
    font-size: 12px;
    gap: 4px;
  }

  .toggle-switch {
    width: 15px;
    height: 7px;
  }

  .slider:before {
    width: 6px;
    height: 6px;
    left: 1px;
  }

  input:checked + .slider:before {
    transform: translate(7px, -50%);
  }
}
</style>

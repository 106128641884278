import { PostProps } from "@/types"

const post18: PostProps = {
  key: 18,
  journeyKey: 1,
  x: '26%',
  y: '18%',
  zIndex: 110,
  location: 'Sihanoukville',
  title: 'Chinese rule over a Cambodian town',
  content: [
    {
      type: 'text',
      value: [
        `The ferry brings me back to the port. There is an Amazon cafe (kind of like Starbucks, but more affordable). I settle there for a few hours of work. I feel really zen and focused. I think it is due to having had such a fruitful and adventurous weekend. It truly holds that when you exhaust your body, it’s the best rest for the mind.`,
        `In the evening, I take a tuktuk to my hostel. I am observing the city and it really surprises me. There are wide roads, big buildings, construction work for more buildings, and many, many colourful lights. The most prominent thing is the insane amount of casinos. This place looks like an Asian version of Las Vegas. I am pretty impressed and cannot stop wondering what brought so much seeming wealth to this place.`,
        `In my second break, I walk down the road to find something to eat. It is only now that I notice that everything - literally everything - is Chinese here. The restaurants serve a lot of food I cannot really make sense of, plus it is probably mostly meat-based. There are more Chinese letters than Khmer ones. Dinner costs about 5-6 dollars here (which is relatively expensive for Southeast Asia, but I find a little market in front of a big Chinese shopping mall. Here, I can eat at a Cambodian stand for 3 dollars.`,
        `I sit down and watch a Chinese movie with Chinese subtitles on a ginormous screen on the wall of a big shopping mall. The Cambodian shop owner sits next to me while I eat and we converse about anything - he is curious about me, my travels, and my life back home. I am curious about his life here in this strange town.`,
        `"Yes there are many Chinese people here. They build lots of casinos and shops", he says.`,
        `"Is Cambodia a democracy?" I try to look for more answers.`,
        `"Yes, theoretically it is a democracy. But in secret it is 50% democracy and 50% socialism."`,
        `"Is there a friendship between Chinese and Cambodian people?"`,
        `"Yes", he says with hesitation. I cannot judge his honesty on this one. He seems like a very non-conflict person. I cannot help but think that the Chinese developments upsets some people.`,
        `To me, the town feels a bit soulless. My hostel is a small welcoming space inside this big world which seems very foreign and uninviting.`,
        `In Cambodia, my expenses are sky-rocketing. You suddenly spend 10 dollars for a bed, 4 dollars for breakfast, 5 dollars for lunch, 5 for dinner, 1 for water (which would already make at least 25 per day), and if you need to move to a different place, easily 20 dollars for a bus. This is something I did not experience in Thailand or Laos.`,
        `The next morning, I walk about 15 minutes to a grocery store which should have non-chinese products too. I hope this way I save some money. However, I see that buying a yoghurt, a pastry and some fruit would cost about 5 dollars. I decide to enjoy breakfast at a bakery for the same price.`,
        `Later, I book a tuktuk through an app and confirm my name with the driver. The driver does not bring me to the beach, as I had wished, and we find out he took the wrong customer. This is a little example on how people sometimes nod to you even when they don't understand, which happened to me many times already. We go back to the pick up point, where I meet my driver and the other driver finds his customer.`,
        `At the beach, I take a nice me-time. I use it for swimming, yoga, meditation, and listening to music. The swimming does not last long, because I end up picking up trash I would otherwise bump into and carry it to the trash can. A kind, lovely Cambodian lady comes by and sells me some bracelets. She has the kind I have been looking for and they are a good price! She stays a little bit for a conversation and without me asking (which indicates it truly bothers her), she opens up about the situation in this town.`,
        `"There are a lot of Chinese people here. It makes me very sad because they build casinos, often leave constructions unfinished, and abandon many buildings. They bring their own people so they do not create more jobs for Cambodian people. There is nothing left for us. The prices go really high. It makes me really sad. A few years ago it was not like this."`,
        `I think about what she said. I am all about acceptance and immigration integration. I also support it in Europe, which is already culturally really complex and where the birth rate is going down anyways. However, the situation here feels rather exploitative.`,
      ]
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/01_southeast_asia/post18/1.jpeg',
          description: ['casinos on a big roundaboud close to the hostel'],
        },
        {
          src: '/images/01_southeast_asia/post18/2.jpeg',
          description: ['watching a Chinese movie while enjoying my Cambodian dinner and talking to the locals'],
        },
        {
          src: '/images/01_southeast_asia/post18/3.jpeg',
          description: ['a cute bakery to hide from the town in'],
        },
        {
          src: '/images/01_southeast_asia/post18/4.jpeg',
          description: ['on my way towards Koh Chang, happy to get back to Thailand'],
        },
      ]
    },
    {
      type: 'text',
      value: [    
        `The next day, I move to a town near the border. I take a break from work to go for dinner. At the restaurant, I meet a Swiss guy, who has now lived in Asia for 16 years, out of which 11 were here. I explain I am in this town only to cross to Thailand the next day.`,
        `"Oh but that is a mistake, this place has a lot to offer! There are waterfalls, beautiful nature and really nice beaches."`,
        `"I was in Sihanoukville at the beach and I do not trust the beaches here anymore."`,
        `"Oh but Sihanoukville is terrible. It used to be amazing but now it is not so clean anymore. And it was taken over by Chinese people."`,
        `"Why did that happen?" I take my chance to get more information on this.`,
        `"Well, corruption. The government gets some money from this, and then turns a blind eye. They let Chinese people enter and build without much regulation, and they can do whatever they want. There used to be 40 casinos, now there are 150! It is really sad."`,
        `He confirms what I had already suspected. I have seen a strong Chinese influence in Laos, but that makes sense because Laos is a socialist country. There, it also seemed meaningful for the locals; the Chinese companies build infrastructure and industry there, in exchange for exporting valuable goods from Laos. There were many Chinese people, but mostly for tourism, so that mostly supports the locals. It seems, however, that China has a strong influence on many South Eastern countries, whether they claim to be democratic or socialist.`,
        `The next morning, I get a last bitter taste of Cambodia. I take a taxi to the border. It is only a 15 minute drive, which should cost about 2 dollars, but the driver wants 8. We agree on 4, then he brings me to the border and claims we agreed on 5. Another reminder to repeat things again even if you think you reached an agreement.`,
        `I end up spending more money at the Cambodian border. I am supposed to have my e-visa printed out, which I don't. Before, I had never had to do that, so I assumed it would be fine - especially when leaving the country. I have to exchange money, because even though we are still in Cambodia, they ask Thai baht for the printing. The exchange rate is really unfair, costing me about 25% of the exchanged money. And on top of that, the lady at the stand tries to scam me. I have a calculator ready and when I hear her incorrect amount, I fight her. I show her the calculation on my phone over and over again. Eventually, she understands I will not give in and she gives me the right amount of money (which is already a rip off).`,
        `The printing is even more unfair. I pay 4 dollars for two pieces of paper. I know this whole circus is all a good business plan but I have no choice. I want to cross the border.`,
        `At the Thai border, I have no issue. Everyone is fair and kind. The first thing that I do is walk to a 7-eleven, which I missed dearly. Here, a yoghurt costs 50 cents instead of 2 dollars. A water bottle costs 30 cents instead of 1 dollar.`,
        `I am happy. Coming back to Thailand feels like coming home. Here, people do not try to squeeze as much money from you as possible. Thailand feels familiar to me, which is strange because it is still so crazily different from Europe. I take a bus to the port town and enjoy remembering how an asphalt road feels like. I do not have any bus or ferry tickets, today I am just winging it - arriving at a place and finding my next connection. I plan to get to in Koh Chang today, we will see what time I can get there. I am feeling adventurous!`
      ]
    },
  ],
  signature: '08/04/2024 - 11/04/2024'
}

export default post18

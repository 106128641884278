import { PostProps } from "@/types"

const post21: PostProps = {
  key: 21,
  journeyKey: 1,
  x: '26%',
  y: '21%',
  zIndex: 110,
  location: 'Bangkok',
  title: 'Reaching the travel burnout, reunion with my favorite Thai person',
  content: [
    {
      type: 'text',
      value: [
        `My last destination is the same as my first one. I will be spending my last 5 days of the travel in Bangkok, before I catch my flight on the 6th day. Later in the week, I expect to reunite with Eif. She was a very influential person to me in Bangkok. She took me under her wings, spent every day with me and made this giant city manageable for me. I can’t wait to see her again.`,
        `This time, I decided to book a hostel someone recommended to me - Rest is more hostel. The good thing about this place is - it is really close to the Golden Mount Temple. The bad thing is, it is right on the side of a giant wide loud road. Walking to the closest 7-eleven is not the most pleasant experience.`,
        `After recharging my battery, I go out and find a nice veggie vendor. I sit down, enjoy a big dinner and watch the TV there. The area is really cute. I believe I have not seen it before. It has small, peaceful, cute little alleys and canals. The thing that amazes me about Bangkok the most is: how can it be such a wild, big city, and a peaceful, quiet village at the same time?! I am enjoying being in the village today.`
      ]
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/01_southeast_asia/post21/1.jpg',
          description: ['cute little streets that make you feel like you are in a village'],
        },
        {
          src: '/images/01_southeast_asia/post21/2.jpg',
          description: ['these houses give off the same feeling'],
        },
        {
          src: '/images/01_southeast_asia/post21/7.jpg',
          description: ['the houses by the canals have an entrance half a meter from the canal'],
        },
        {
          src: '/images/01_southeast_asia/post21/8.jpg',
          description: ['Finally seeing Khao San road'],
        },
      ]
    },
    {
      type: 'text',
      value: [
        `On Friday, I eat breakfast from 7-eleven. After breakfast, I notice I need to recharge my batteries again. I am starting to get the feeling that I might have a travel burnout. Or it could be that I know it is coming to an end and I stopped trying hard. I know Bangkok is full of art, fun, and excitement. I could spend all day outside, and meet amazing people. But I cannot find the spark in me for this. I need to stay in bed a lot.`,
        `I spend the afternoon in a cute, Japanese cafe by the canal. Later, I manage to see my favorite Golden mount temple again. It made a great impression on me the first time. It feels like a friendly reunion now. Since I am already here, I pay a visit to other temples nearby - and am rewarded with a beautiful singing prayer.`,
        `At night, I make my way to Khao San road. Even though my first hostel was quite close to the place, I never really found out where it was! I always assumed I would stumble upon it - since it is supposed to be so big and loud - but I only kept going around it every time. Each day was so full of experiences and insights, that I rarely had energy to stay up late and try to find a party. So today, I am walking through Khao San for the first time ever. I am amused by the amount of people standing in front of the bars, luring me it. Their most common trick is - ‘We have balloons!’ (Not the kids party kind of balloons, more like laughing gas balloons). I decide to opt for a different kind of product and indulge in roti. There are stall after stall selling them and at this point, I just cannot not resist anymore.`
      ]
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/01_southeast_asia/post21/3.jpg',
          description: ['the minimalistic Japanese cafe'],
        },
        {
          src: '/images/01_southeast_asia/post21/4.jpg',
          description: ['staircase towards the Golden mount temple'],
        },
        {
          src: '/images/01_southeast_asia/post21/5.jpg',
          description: ['the view from my favorite temple'],
        },
        {
          src: '/images/01_southeast_asia/post21/6.jpg',
          description: ['the temple where the singing came from'],
        },
      ]
    },
    {
      type: 'text',
      value: [
        `On Saturday, I have a slow morning again - still in need of recharging. Before it gets too late, I make it to the contemporary gallery. This was a great idea, since I get the last bit of amazing Asian art. The artist herself is also present at the gallery so I manage to talk to her about her art and her inspiration. She teaches at the university and some of the pieces are a part of a bigger project. For example, an installation where she let many people from a Chinese community sew segments of fabric together, to make one big art piece. This was in the hope of including all kinds of people in art making - and to connect them through art.`,
        `In the evening, I rest by the white fortress close to Khao San. I check out some bookstores. I call a taxi back home.`
      ]
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/01_southeast_asia/post21/12.jpg',
          description: ['inside the gallery'],
        },
        {
          src: '/images/01_southeast_asia/post21/10.jpg',
          description: ['the artists painting: little Chinese girl'],
        },
        {
          src: '/images/01_southeast_asia/post21/11.jpg',
          description: ['the installation with the fabric'],
        },
        {
          src: '/images/01_southeast_asia/post21/9.jpg',
          description: ['meditating statues'],
        },
      ]
    },
    {
      type: 'text',
      value: [
        `It is Sunday now and Eif is available to meet up! We go to a science park together and look at exhibitions. Stuff like plants, butterflies, rocks,... I try to learn what I can from this educational activity. We sit down for lunch and talk about our experiences. I share some travel stories and compare the other countries I visited with Thailand. Eif had a work project in Cambodia, so she shares stories from there.`,
        `Later that day, we have a ticket to a planetarium. We lay down in the chairs, look up and watch a movie about our galaxy. Even though I do not ask for it, Eif patiently translates most of the movie to me! What a sweetheart...`
      ]
    },
    {
      type: 'text',
      value: [
        `I wake up not in a small hostel room, but in a nice open, sunlit apartment high in the sky. Tonight, Eif let me stay in her apartment - she is currently not using it. I slept amazingly. I love this building. I check my phone and see that Eif has even ordered breakfast for me. It is now waiting at the lobby. I take the elevator. I feel fancy. I pick up my breakfast and enjoy.`,
        `We meet up for a nice Japanese coffee place. Eif orders an amazing matcha lattee. I order the same thing of course. It is now kind of a meme - I always order what Eif does - because she knows best. Afterwards, we get back to the apartment building and share a lunch in the building’s restaurant. I try a typical Thai dessert - an incredibly sweet porridge with colorful jelly thingies floating around. This gives me enough power to have a productive, work-focused afternoon.`
      ]
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/01_southeast_asia/post21/13.jpg',
          description: ['observing giant insects at the science park'],
        },
        {
          src: '/images/01_southeast_asia/post21/14.jpg',
          description: ['the planetarium'],
        },
        {
          src: '/images/01_southeast_asia/post21/15.jpg',
          description: ['dinner spot with Eif', 'food cost 30 Baht here!'],
        },
        {
          src: '/images/01_southeast_asia/post21/16.jpg',
          description: ['another place, another time ordering the same thing as Eif'],
        },
      ]
    },
    {
      type: 'text',
      value: [
        `I wake up to my last Thai morning - bittersweet. It is Tuesday, 30th of April, officially the last day of my travel. I make sure I packed everything and leave the apartment nice and clean.`,
        `Sweet Eif picks me up to bring me to the airport. On the way there, we talk about my feelings leaving Asia. In the background, we watch a tiktok live - Eif got into some admin group of a Korean influencer - if I understood it correctly - and needs to keep updated. If something goes wrong in the livestream, she is authorized to ban people, for example.`,
        `We say goodbye at the airport. She is the first person I met and now also the last person I say goodbye to. Things have come full circle.`,
        `I mostly feel excited for my flight - I kind of enjoy airports and flying. If it was not bad for the environment, I would do it all the time. Now I have only two hours till departure, but everything goes smoothly and I am soon at the gate. I hold onto my boarding pass now. This is a clear proof to me that it is really happening. I am at the right airport, at the right time. I did not make any mistakes.`,
        `First, I take a 3 hour flight to Shenzhen - I am impressed that even such a short flight hands out free food and drinks. In Shenzhen, I have an 8 hour long overlay. All I do at this airport is simply exist. Due to the great firewall, I do not have access to any online services. No Youtube, Spotify, Instagram, Netflix, Messenger, even email. Yet I am surprised how quickly the time passes, even when you just observe people and occupy your head with your thoughts.`,
        `The second flight is quite comfortable. I am lucky to find a double seat for myself. This means a decent sleep. A good end to an amazing trip - before I arrive in Europe, refreshed, restarted, my soul fuller with new experiences and memories that are sometimes hard to explain. Such magic!`
      ]
    },
  ],
  signature: '25/04/2024 - 30/04/2024'
}

export default post21

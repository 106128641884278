<template>
  <div class="wrapper"> 
    <div :class="['zoom-container', colorClass]" ref="zoomContainer">
      <div class="list">
        <div v-for="(post, index) in posts">
          <div :class="['post-header', { 'selected': selectedPostIndex === post.data.key, ...colorClass }]" @click="goToPost(index)">
            <div :class="['circle-container', { 'selected': selectedPostIndex === post.data.key, ...colorClass }]">
              <div class="circle invisible">
                <div class="circle filled">
                  <div class="circle-number">{{ post.data.key + 1 }}</div>
                </div>
              </div>
            </div>
            <div class="post-location-title-wrapper">
              <div :class="['post-location-title-col', { 'selected': selectedPostIndex === post.data.key }]">
                <div class="post-location">{{ post.data.location }}:</div>
                <div class="post-title">{{ post.data.title }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="top-fade"></div>
    <div class="bottom-fade"></div>
  </div>
</template>

<script>

export default {
  name: 'List',
  computed: {
    posts () {
      return this.$store.state.posts
    },
    selectedPostIndex () {
      return this.$store.state.selectedPostIndex
    },
    colorClass () {
      return this.$store.getters.colorClass
    },
  },
  methods: {
    goToPost (index) {
      this.$emit('go-to-post', index)
    },
    scrollToSelectedPost () {
      this.$nextTick(() => {
        const container = this.$refs.zoomContainer
        const selectedPost = container?.querySelector('.post-header.selected')
        if (selectedPost && container) {
          const containerRect = container.getBoundingClientRect()
          const selectedRect = selectedPost.getBoundingClientRect()
          
          const scrollOffset = selectedRect.top - containerRect.top + container.scrollTop - 20 // Added 20px extra space

          container.scrollTo({
            top: scrollOffset,
            behavior: 'smooth'
          })
        }
      })
    }
  },
  mounted () {
    this.scrollToSelectedPost()
  }
}
</script>

<style scoped lang="scss">
@import '../main.scss';

$sircle-size-map: 35px;
$circle-font-size: 16px;

$circle-size-md: 25px;
$circle-font-size-md: 12px;

.wrapper {
  position: relative;
  width: 100%;
  max-height: 545.59px;
}

.zoom-container {
  width: 100%;
  height: 100%;
  aspect-ratio: 10 / 5.7;
  max-height: 545.59px;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;

  &.green { scrollbar-color: $green-light transparent; }
  &.blue { scrollbar-color: $blue-light transparent; }
}

.list {
  margin-top: 40px;
  width: 100%;
  padding: 20px;
}

.top-fade, .bottom-fade {
  position: absolute;
  left: 0;
  width: 100%;
  height: 50px;
  pointer-events: none;
}

.top-fade {
  z-index: 450;
  top: 0;
  background: linear-gradient(to bottom, rgb(15, 15, 15, 1), rgba(15, 15, 15, 0));
}

.bottom-fade {
  z-index: 450;
  bottom: 0;
  background: linear-gradient(to top, rgb(15, 15, 15, 1), rgba(15, 15, 15, 0));
}

.post-header {
  display: flex;
  align-items: flex-start;
  text-transform: uppercase;
  font-family: 'HedvigLetters';
  margin-bottom: 8px;
  color: $cappuccino;
  cursor: pointer;
  transition: color 0.6s ease;
  flex-wrap: nowrap;

  &:hover {
    color: $cappuccino-dark;
  }

  .post-location-title-wrapper {
    display: flex;
    flex-direction: column;
  }

  .post-location-title-col {
    display: inline-flex;
    flex-wrap: wrap;
    flex-direction: column;

    &.selected {
      color: $cappuccino-dark;
    }
  }

  .post-location {
    font-size: 18px;
    font-weight: bold;
    margin-right: 15px;
    cursor: pointer;

    @media only screen and (max-width: 1000px) {
      font-size: 14px;
    }
  }

  .post-title {
    width: 95%;
    font-size: 14px;

    @media only screen and (max-width: 1000px) {
      font-size: 12px;
    }
  }
}

.post-header:hover .circle {
  background-color: $cappuccino-dark;
  transition: background-color 0.6s ease;
}

.post-header:hover .invisible {
  box-shadow: 0 0 18px $green-light;
  transition: box-shadow 0.6s ease;
}
.post-header.blue:hover .invisible {
  box-shadow: 0 0 18px $blue-light;
}

.post-header:hover .circle-number {
  color: $green-light;
  transition: color 0.6s ease;
}

.post-header.blue:hover .circle-number {
  color: $blue-light;
}

.circle-container {
  flex-shrink: 0;
  position: relative;
  width: $sircle-size-map;
  height: $circle-size-map;
  margin-right: 10px;

  @media only screen and (max-width: 1000px) {
    width: $circle-size-md;
    height: $circle-size-md;
  }
}

.circle {
  cursor: pointer;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transition: box-shadow 0.6s ease, background-color 0.6s ease;
}

.invisible {
  box-shadow: 0px 0px 4px $cappuccino;
  background-color: transparent;
  transition: box-shadow 0.6s ease;
}

.filled {
  background-color: $cappuccino;
}

.circle-number {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: $black;
  font-family: 'HedvigLetters';
  font-size: $circle-font-size;
  font-weight: bold;
  transition: color 0.6s ease;

  @media only screen and (max-width: 1000px) {
    font-size: $circle-font-size-md;
  }
}

// selected
.selected {
  z-index: 400 !important;
}

.selected .invisible {
  box-shadow: 0 0 18px $green-light;
}
.selected.blue .invisible {
  box-shadow: 0 0 18px $blue-light;
}

.selected .circle-number {
  color: $green-light;
}
.selected.blue .circle-number {
  color: $blue-light;
}

.selected .circle {
  background-color: $cappuccino-dark;
}

</style>
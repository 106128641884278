<template>
  <div class="App" :class="colorClass">
    <div class="bg-img">
      <img src="./backgrounds/bg-5.jpg"/>
      <img src="./backgrounds/bg-5.jpg"/>
      <img src="./backgrounds/bg-5.jpg"/>
      <!-- favorites: 2, 5, -->
    </div>
    <div class="fade-img"></div>
    <div class="content-wrapper">
      <div class="content">
        
        <!-- left arrow -->
        <div class="arrow-space" :class="{'arrow-space-visible' : showButton}">
          <button v-if="selectedPostIndex > 0" @click="prevPost" :class="[colorClass, 'arrow-button']">
            <svg class="arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 960 960" width="48px" height="48px" fill="white">
              <circle v-if="!isTouchDevice" class="circle" cx="480" cy="480" r="400" stroke="white" stroke-width="40" fill="none"/>
              <g transform="scale(0.6) translate(300, 300)">
                <path d="M543 738L298 493q-6-6-6-14t6-14l245-245q9-9 22-9t21 9q9 9 9 21.5t-9 21.5L364 480l222 222q9 9 9 21.5t-9 21.5q-9 9-21.5 9t-21.5-9Z"/>
              </g>
            </svg>
          </button>
        </div>

        <!-- Router (post) -->
        <router-view @mouseover="mouseOverPost=true" @mouseout="mouseOverPost=false" />

        <!-- right arrow -->
        <div class="arrow-space" :class="{'arrow-space-visible' : showButton}">
          <button v-if="selectedPostIndex < posts.length - 1" @click="nextPost" :class="[colorClass, 'arrow-button']">
            <svg class="arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 960 960" width="48px" height="48px" fill="white">
              <circle v-if="!isTouchDevice" class="circle" cx="480" cy="480" r="400" stroke="white" stroke-width="40" fill="none"/>
              <g transform="scale(0.6) translate(300, 300)">
                <path d="M417 738L662 493q6-6 6-14t-6-14L417 220q-9-9-22-9t-21 9q-9 9-9 21.5t9 21.5L596 480 374 702q-9 9-9 21.5t9 21.5q9 9 21.5 9t21.5-9Z"/>
              </g>
            </svg>
          </button>
        </div>
      </div>
  </div>

  </div>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
      mouseOverPost: false,
    }
  },
  computed: {
    journeys () {
      return this.$store.state.journeys
    },
    posts () {
      return this.$store.state.posts
    },
    selectedPostIndex () {
      return this.$store.state.selectedPostIndex
    },
    selectedJourneyIndex () {
      return this.$store.state.selectedJourneyIndex
    },
    isTouchDevice () {
      return 'ontouchstart' in window || navigator.maxTouchPoints > 0
    },
    showButton () {
      return (!this.mouseOverPost && !this.$store.state.notFound) || this.isTouchDevice
    },
    colorClass () {
      return this.$store.getters.colorClass
    }
  },
  methods: {
    nextPost () {
      this.$store.dispatch('nextPost')
    },
    prevPost () {
      this.$store.dispatch('prevPost')
    },
  },
  mounted () {
    window.scrollTo(0, 0)
  },
}
</script>

<style lang="scss">
@import 'main.scss';

body {
  margin: 0;
  padding: 0;
  background-color: $brown;
}

.App {
  position: relative;
  overflow: hidden;

  ::-moz-selection { /* Code for Firefox */
    background: $green-light;
    color: $brown;
  }

  ::selection {
    background: $green-light;
    color: $cappuccino-dark;
  }
}

.App.blue {
  ::-moz-selection { /* Code for Firefox */
    background: $blue-light;
  }

  ::selection {
    background: $blue-light;
  }
}

.content-wrapper {
  position: relative;
  z-index: 20;
  padding: 0px 2%;

  /* allow more space on left and right for big screens */
  @media only screen and (min-width: 1200px) {
    padding: 0px 15%;
  }
}

.content {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row; /* arrow - post - arrow */
  gap: 3%; /* space between post and arrows */

  /* allow more space on left and right for big screens */
  @media only screen and (min-width: 1200px) {
    gap: 5%; /* space between post and arrows */
  }
}

.bg-img {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 5;
}

.bg-img > * {
  overflow: hidden;
  object-position: center;
  object-fit:cover;
  transform: scale(1.02);
  background-repeat: repeat-y;
  transform-origin: center;
  animation: zoomBackground 1.5s ease-in-out forwards;
  filter: blur(8px);
  z-index: 5;
}

.fade-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: gray;
  opacity: 0.6;
  z-index: 10;
  animation: fadeBackground 1.5s ease-in-out forwards;
}

@keyframes zoomBackground {
  0% {
    transform: scale(1.01);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes fadeBackground {
  0% {
    opacity: 0.6;
  }
  100% {
    opacity: 0.2;
  }
}

.arrow-space {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0; /* initially hide the arrows */
  pointer-events: none; /* prevent interaction with hidden arrows */
  transition: opacity 1s ease;
}

.arrow-space-visible {
  opacity: 1;
  pointer-events: auto; /* allow interaction with arrows when visible */
}

.arrow-button {
  position: fixed; /* Fixed positioning relative to the viewport */
  top: 50%; /* 50% from the top of the viewport */
  transform: translateY(-50%); /* Adjust position to center the element */
  height: 60px;
  width: 60px;
  border: none;
  cursor: pointer;
  appearance: none;
  background-color: inherit;

  @media only screen and (max-width: 700px) {
    .arrow {
      max-height: 30px;
      max-width: 30px;
    }
  }

  .arrow {
    transition: fill 0.6s ease;
  }

  .circle {
    transition: stroke 0.6s ease;
  }

  &:hover {
    .arrow {
      fill: $green-light;
    }
 
    .circle {
      stroke: $green-light;
    }
  }
}

.arrow-button.blue {
  &:hover {
    .arrow {
      fill: $blue-light;
    }
 
    .circle {
      stroke: $blue-light;
    }
  }
}

</style>

import { PostProps } from "@/types"

const post19: PostProps = {
  key: 19,
  journeyKey: 1,
  x: '26%',
  y: '19%',
  zIndex: 110,
  location: 'Koh Chang',
  title: 'Thai New Year, and sleepy days in bungalow',
  content: [
    {
      type: 'text',
      value: [
        `I am in Thailand! And I have a whole day to figure out how to make it from the Cambodian border to Koh Chang. First I take a (finally cheap, Thai) bus to a town called Trat. At the station, I talk to a taxi driver. She suggests I wait and see if more people would join me on the way to the port. After about one hour, no one shows up. I climb onto the big tuktuk and enjoy the ride on my own.`,
        `Next, I take a rather quick and cheap ferry to the big Island. I can see there is a large asphalt road all around it. This is rather new to me, since Don Det and Koh Rong Samloem did not have much infrastructure.`,
        `On the island, I join other travellers in a taxi that carpools us to different places. I get dropped off at my hostel - only to realize, this is not my hostel. I saved it in my map because someone recommended it to me. However, I ended up booking a less fancy, cheaper, more authentic hostel.`,
        `I get back on the road. It is raining now - the second time since I came to Asia! My taxi is gone and I do not see any other ones in sight. I won’t waste any time, let’s start hitchhiking!`,
        `I get picked up by an adorable Chinese family. A couple and three little girls. Everyone is really curious - even the little girls find courage to speak English to me. The mom sometimes takes videos and pictures of me without asking. I decide not to say anything. She was so sweet that if she had asked consent, I would’ve definitely given it.`,
        `They drop me off at their property and cannot really bring me further. Perhaps I can walk the rest, which should only be about 10 minutes. A nice Thai woman passes by on a motorbike and does not let me walk on the side of the road. I do appreciate that - we see some monkeys in the jungle and the road gets a little curvy and unpredictable.`,
        `I am now at the hostel, it is called Cafe del sunshine. So far, this is the most authentically hippie hostel I have been to. It seems rather old, built entirely with wood. There are countless pieces of art, photographs, books and decorations all around. It seems to me that the owner has been collecting these for decades. They probably had a big appreciation for rock/hippie/psychedelic bands, as I see and hear them all around.`,
        `In the dormitory room, I meet an Italian-Thai guy, Timoteo. We get a little confused, because they booked us for the same bed. After talking to the receptionist again, they move my bed next to his. Timoteo rests inside, while I go to the beach to relax, swim, read, and do a little yoga.`,
        `For the yoga part, I find a spot between two big rocks, which feels a bit discrete. Still, while traveling, it’s often hard to find a private spot for the practice. Today, even though I think it is clear I do not want to be bothered, there is a guy who watches me with no shame. Despite not giving him any attention, he comes up to me. To stay polite, I do engage in his small talk for a bit. From the way he acts, I can tell he has a big ego and enjoys talking to women - even when they do not show interest. Eventually, he asks me whether I will be here tomorrow. ‘I will not - now I will get back to my practice,’ I say and turn upside down.`,
        `Back at home, I encounter Timo again and we go for dinner together.`
      ]
    },
    {
      type: 'text',
      value: [
        `On Friday, I work for a while in the morning. Then I spend my evening at the beach. Later, I share dinner with Timo again, and even more excitedly, some roti. Timo is a sweet person and becomes my travel buddy for the upcoming days.`
      ]
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/01_southeast_asia/post19/1.jpg',
          description: ['arriving with the taxi'],
        },
        {
          src: '/images/01_southeast_asia/post19/2.jpg',
          description: ['my first of many beach visits'],
        },
        {
          src: '/images/01_southeast_asia/post19/3.jpg',
          description: ['the downstairs of the hostel bar'],
        },
        {
          src: '/images/01_southeast_asia/post19/4.jpg',
          description: ['I could look through all these things endlessly'],
        },
      ]
    },
    {
      type: 'text',
      value: [
        `It is now Saturday, 13th April, which means the Songkran begins! This is the 3-day long celebration of the Thai New Year. So far, I am unaware of what that means in terms of how my day will develop. I am in for a sweet surprise.`,
        `During the morning, Timo is at the gym - how admirable! Keeping the fitness routine while traveling… I go to the hostel bar, lay down in the hammock and start reading my book. I end up talking to an amazing, beautiful Colombian woman. We compare the culture of the warm and fiery South American people to the peaceful and hardworking nature of Asian people. She gets me inspired to travel to South America - although I am afraid of it. I can only imagine going with a man. ‘And start with Mexico! That’s a good way to slowly get used to the different culture. It is not as dangerous as other places but already teaches you how to behave there,’ she advises me.`,
        `In the afternoon, Timo and I hop on a motorbike and experience the Sonkgran tradition firsthand. Basically, Songkran is one giant water fight. On the side of the road, at every shop, restaurant and house, there are groups of people (adults and children) standing with water guns, hoses, and big buckets of water. Everyone is ready to splash water on those coming by whether on foot, motorbike or car. Even people on the road are ‘armed’`,
        `As Timo and I drive around, we get dozens of these splashes. Right at the beginning of our journey, we are stopped by a big family and a woman puts white clay on our faces. ‘For good luck’, she says. This is another tradition. I find it charming.`,
        `We drive down south to the pier. There is a nice market there. We share ice cream, pancakes and other good stuff. Since Timo is partially Thai, he has amazing recommendations and knows exactly what to show me.`,
        `At some point, I tell Timo about my experience in Sihanoukville and he is quite startled by this. He googles more about it and reads an article about how it is not safe for women and something about catcalling. I am now realizing: “that’s what that was!” I remember hearing people shout things at me - even whistling. I just always thought they do that to a dog or something. I have felt so incredibly safe in Asia that it did not even cross my mind there could be catcalling! Asian men have mostly been nothing but extremely respectful. Not in Sihanoukville though - as we found out.`,
        `After lunch, we continue further down south to find an abandoned resort. The theme seems to be ships. There is a giant ship hotel; it reminds me of the Titanic. Next to it, there are many smaller ships, they look like smaller apartments. We stay away from the giant ship, but take a little look inside the smaller ones. It is really strange. In some rooms, we find items that look like the guests have left behind. It almost looks scary. What happened here?`,
        `In one of the ships, we find a reception and a restaurant. We discover some administration papers. We play a little game and try to find out the latest date we know it was still in function. It is strange, but we can go through not only some orders for the restaurant, but quite some personal papers too… We can see exactly who was accommodated here. Interesting that all of this was left behind.`,
        `After some googling, we discover that the resort was just a little bit too ambitious of a plan. There was never enough tourism on this island to sustain it. The current state is: there are tourists but they are rather scattered around the island in hostels and bungalows. I bet not many people would choose a giant creepy cruise ship to spend their vacation in.`,
        `After a quick swim at the beach right next to the ships, we come back for dinner. We find people who are also from our hostel and join them. Eventually, we decide to take a taxi and find a nice bar. We ask the taxi driver herself. She is local so she must know the best place to go. And she does indeed bring us somewhere special.`,
        `To my surprise, we appear in quite a large outdoor club - so tonight we dance! Apart from us, I do not really see any other tourists. It is mostly Thai people enjoying their Songkran night to the fullest. The music is dynamic - that tropical fast repetitive beat. There is water splashing from every direction. To get to the club, we quite literally have to pass through a waterfall. And again, get a bunch of clay spread on our faces. It feels nice to be completely soaked, as it might be around 30 degrees even during the night.`,
        `Everyone seems in a great mood. We make friends with the locals. The group is fun. We drink and dance together. After a few hours, the music stops and a live band comes on. That is a complete surprise to us. The singer lady has an incredible energy - a true entertainer. There is a moment, however, where they need to adjust something with the cables, because of all the water everywhere. I think a fuse blew out. The cable management and the wet environment is probably not the safest combo.`,
        `After the concert, we gather everyone and decide to call our taxi lady again. She drops us off on our street and we think we would call it the night. Until…`,
        `We see a group of 4 people dancing in front of one of the houses to nice hippie music. They immediately invite us to join. Without hesitation, we run to them to join them in dancing and talking. It is an incredibly chill atmosphere. We are in skirts and shorts, barefoot, wet, with beers in our hands, just vibing on the street. I thought we would join them only for a bit, but everyone seems to have a good time. We become friends and share genuine conversations. The host shows me around the house and lets me adore all the wall paintings he made so far. The house is still in progress. I know now already that this will truly be an artists’ home.`,
        `Right before crashing in our beds in the hostel, we join a techno party closeby. Here, we do not stay that long, as it is getting really late now - or I might say, really early.`,
      ]
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/01_southeast_asia/post19/5.jpg',
          description: ['clay on our smily faces'],
        },
        {
          src: '/images/01_southeast_asia/post19/6.jpg',
          description: ['the port', 'the market is right behind'],
        },
        {
          src: '/images/01_southeast_asia/post19/8.jpg',
          description: ['the big, abandoned cruise ship hotel'],
        },
        {
          src: '/images/01_southeast_asia/post19/7.jpg',
          description: ['the smaller ships'],
        },
        {
          src: '/images/01_southeast_asia/post19/9.jpg',
          description: ['a quick snap from the concert at night'],
        },
        {
          src: '/images/01_southeast_asia/post19/10.jpg',
          description: ['one of the quotes from the house of our unexpected party host'],
        },
      ]
    },
    {
      type: 'text',
      value: [
        `It is the morning after the party. Everyone is dealing with it in a different way. After breakfast, I choose to go to the beach. Songkran still goes on, so I arrive there completely soaked. I find out the only thing I can bring myself to do is to listen to music all day long. My body is tired. Clearly I am hungover…`,
        `In the evening, I have dinner with Timo again. At the hostel, we meet most of the people from the night before. We sit down, and join in on the deep talks. It is really enjoyable, we all stay up till quite late - even though most people wake up early to leave the place tomorrow - including me. Some of the guys get really stoned - to the point that they lay down, sometimes show a big smile, but struggle to communicate with us. Either way, they seem to have a good time. The rest of us keep talking.`
      ]
    },
    {
      type: 'text',
      value: [
        `On Monday, I pack my stuff and say goodbye to Timo and other people who are still around. I hitchhike and get picked up by a nice French guy, who lives on the island and even opened a restaurant here with wife. The whole ride, we go rather slowly so that we can hear each other.`,
        `I am on my way to a nice bungalow place. After having another round of quite a social time, I plan to take a bit more quiet time alone. I need to focus on work anyway. I get accommodated by a nice Thai lady and her (possibly British) husband. They have two cute dogs. One is a little shy and needs time to connect with people, the nother one has a lot of saliva, crooked teeth, walks slowly, and smells… They even have a big turtle!`,
        `After a few hours of working, I decide to find a place to eat. The restaurant right next to the bungalows has European-level prices. I try to find more restaurants but many are closed, probably because today is the last day of Songkran. If I want to go to a more busy place, I need to walk about 10 minutes in either direction to find civilization. I quickly find out that this area is mostly occupied by rich Russian tourists (and sadly, annoyingly flirtatious ones). Apart from my chill bungalows, the other accommodations are all rather fancy.`,
        `I finally find a restaurant, but they attempt to lie to me when I ask them for a vegetarian soup. I get a portion of a beef broth, where there are clearly big chunks of meat floating around. I ask them about the meat pieces, they say it is made of soya.`,
        `I look down at my soup, then up at them again with a big question mark written on my face.`,
        `‘Okay, okay, sorry… it is beef,’ they say.`,
        `I find another place where I manage to get fried rice with egg and veggies. But the lady serving me is really rude to me. Perhaps a bad day. Mine is also slowly deteriorating.`,
        `After dinner, I try to get a smoothie. I ask a lady at the smoothie stand whether she makes her smoothies from fresh fruit. For some reason, I am feeling suspicious. And I see no fruit around.`,
        `“Yes, we do!” she replies confidently.`,
        `I order one smoothie and see her pull out 4 different bottles of sugary syrups, all in different colors. ‘This is not exactly fruit,’ I think, and stop the lady before it is too late.`
      ]
    },
    {
      type: 'text',
      value: [
        `My bad luck seems to continue. On Tuesday, I wake up feeling extremely sick. My head spins, my body feels hot. My muscles hurt. I have a headache and I feel nauseous. Since there is no AC in the bungalow, I left my big fan on at the highest strength. Was that a bad idea? Oh lord, I hope it is not dengue. Some people from the hostel did have it, and it has been quite widespread on this island recently. The best I can do right now is to rest my eyes again and sleep through the day.`,
        `In the evening, I collect all my energy and go talk to my host. She calls me a taxi to the pharmacy. I ask her if, in case I get really bad during the night, I could reach her. ‘Of course! Just knock on this window if you need me.’`,
        `The taxi picks me up and I lay down in the back - sitting hurts and I have no strength in my muscles for it. At the pharmacy, it costs me a lot of energy to explain to the lady how I feel. And even more energy to listen to her closely as she explains every drug and how to use it… I definitely have no energy for two Russian men with beer bellies throwing inappropriate jokes on me. I do not smile or engage. I make it back in the taxi equipped with something that should help. If it does not, the pharmacist said, only then I should start worrying about dengue.`,
        `Once we are back at the bungalows, the daughter of the taxi driver hands me her phone. In google translate, it says: ‘She is sick, does she have someone to care for her?’ This girl is maybe 7 years old. It tears me up. ‘I do, thank you,’ I reply, pay and leave the taxi feeling emotional and relieved.`,
        `On Friday, I work for a while in the morning. Then I spend my evening at the beach. Later, I share dinner with Timo again, and even more excitedly, some roti. Timo is a sweet person and becomes my travel buddy for the upcoming days.`
      ]
    },
    {
      type: 'text',
      value: [
        `The next morning, the host’s daughter brings me a hot cacao from her mom. This is a universal language of love and care. Furthermore, I notice that the shy dog is resting on my porch. I remember what my host said previously: ‘She is shy but with time, she grows fond of people. Then she starts guarding their bungalow. And when they leave, she misses them and gets sad for a bit.’ I feel honored that the dog grew fond of me.`,
        `I sleep today, and for another day. It is only on Friday, that I start getting back on my feet. I get out of the bungalow in the middle of the day - for the first time in four days. It has been so long that I am shocked to see how bright the colors are. The trees are so green! The sky is so blue. And the flowers are aggressively colorful! Today, I try to catch up with work a little bit. And to finally eat something.`,
        `On Saturday, I get a nice lunch, and talk to my family. Then I walk to the beach and swim a little. Being inside for so many days did not have a good effect on my mental health. But listening to the waves and watching the sun slowly setting down has a healing effect on my mind.`,
        `On Sunday, I take a very long, and, (to my current physical state) quite an exhausting walk. This time, I want to see another beach. Here I need to watch out for jellyfish. I enjoy yet another wonderful sunset, before I call a taxi back home - no way I am walking that distance again.`,
      ]
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/01_southeast_asia/post19/12.jpg',
          description: ['the bungalows in the jungle'],
        },
        {
          src: '/images/01_southeast_asia/post19/11.jpg',
          description: ['this is where I slept', '...ALL the time'],
        },
        {
          src: '/images/01_southeast_asia/post19/14.jpg',
          description: ['the healing sunset'],
        },
        {
          src: '/images/01_southeast_asia/post19/13.jpg',
          description: ['beware...'],
        },
      ]
    },
    {
      type: 'text',
      value: [
        `Tonight is my last night at the bungalows. I switched to a bigger, fancier one - I wanted to extend my stay in the original one but that one was booked already. I am not complaining though, I feel like I deserve some luxury after the sickness.`,
        `Here, I sleep yet again with an open window. Since I got sick, I slept like this every night. Almost no use of the fan, just relying on the occasional breeze I feel during the night through the window. I knew that the bungalow next to me was occupied by a girl, so I could also sleep naked. This was my way of combating the hot nights.`,
        `I slept quite well every night. Perhaps also due to the sickness, but I also felt a deep sense of relaxation. I attribute that partially to being in nature - it brings me peace. Every now and then, I would be woken up by a creature of the jungle. Every night, I discovered new, mysterious sounds, usually from birds and insects. Even though I was feeling sick and spent most of my days and nights sleeping, I still felt like I was on an adventure. In the strange strange jungle.`,
        `I am saying goodbye to Koh Chang with a good peace of mind. I had an amazing time at the hostel. My time at the bungalows was challenging, but I handled it well. I feel humbled and ready to move on in my journey with new strength. Tomorrow, I come back to the mainland and arrive in a town called Chanthaburi.`
      ]
    },
  ],
  signature: '11/04/2024 - 21/04/2024'
}

export default post19

import { PostProps } from "@/types"

const post26: PostProps = {
  key: 26,
  journeyKey: 0,
  x: '80%',
  y: '86%',
  zIndex: 110,
  location: 'Paphos',
  title: 'A villa in the banana fields and struggles with the bike',
  content: [
    {
      type: 'text',
      value: [
        `In the afternoon, we took a bus to Paphos. After arriving, we sat down at the bus station and ate our leftover pasta from one box, sitting on the concrete. For some reason, this was one of the most intimate moments of the trip.`,
        `We collected some strength and then walked on towards a road we wanted to hitchhike from. First, a Russian lady brought us close to our destination. Then two guys took us all the way in front of our hotel. They were extremely kind and bought us a soda on the way. But amongst each other they were dramatically arguing about something.`,
        `We entered a dusty road inside the banana field and suddenly we were in front of our hotel. It must truly be the end of the season, because we could book a room with a giant balcony overlooking the hotel pool for a reasonable price. We were also almost the only ones in the whole building. We were beaming with happiness!`,
      ]
    },
    {
      type: 'images',
      value: [
        {
          src: `/images/00_balkans/post26/1.jpg`,
          description: ['banana tree across the road of our accomodation!'],
        },
        {
          src: `/images/00_balkans/post26/2.jpg`,
          description: ['gorgeous beaches in Paphos'],
        },
      ]
    },
    {
      type: 'text',
      value: [
        `Later in the evening, we wanted to go grocery shopping but our experience was similar to in Ayia Napa. Not only was the store 20 minutes walking distance, it was painfully expensive (like 5 times more compared to what we are used to. We made a compromise and bought noodles, to which we later added some canned mushrooms and similar things. In the end it turned out quite good!`,
        `It was almost the weekend and we knew we had to do everything in our power to get things in order. We needed to borrow bicycles and do groceries in a proper grocery store. Staying at a deserted villa with a pool is amazing, but we were cut off from these basic needs: food and transport.`,
        `The next morning, we walked towards Paphos. There were some bike rentals on the edge of our village - and worst case also in Paphos. We quickly understood there is no demand for bikes in our village since every rental only offered 'quads' and 'buggies'. We got allergic to these words.`,
        `Luckily, we managed to catch a bus to Paphos. I was watching the road pass by and thought about how I'm going to cycle all that - just a note, it was hilly.`,
        `We got our bikes and took a break at a café. Halfway back home, there was a Lidl and we suddenly appeared in a paradise. We bought everything we wanted, all our healthy yummy food that we would source from for a long while. Then we continued biking. I am not sure how I would do it without Thomas's constant reassurance and patience with me. Suddenly, we were at the road we took the bus from and we were happily surprised.`,
        `When we got back home, we quickly made some lunch. Now everything was in order. However, we only had a few more hours to explore before the sun went down. It was the afternoon but for us, the day truly only started now.`,
        `We biked towards the cape. The plan was to go to the white river beach. And as we were almost finally biking down towards it, suddenly we heard a loud 'pssssssss'.`,
        `My tire got flat. We thought that we finally won today but this has to happen. We called the guy from the rental and he promised to bring a new bike. He expressed anger that we called him on a Cypriot public holiday. But right after, he turned into a kind man, as if that was truly his natural state, but he still wanted to let us know about his sacrifice.`,
        `I had a new bike and we decided to walk the rest of the way. We met a cute British-Cypriot couple. They talked about the beaches and which ones are the most beautiful and which are the most dangerous. The conversation steered to the dangerous undercurrents. Apparently, at some more beaches along the coast, few people die every year. We discussed which beaches to avoid. We also received some advice on what to do if we get caught by an undercurrent: do not swim towards the beach, but to the side. That way you leave the undercurrent and you can swim freely. I remembered our experience from Cape Greco, where swimming indeed seemed a lot more feasible once we targeted the archipelago on the side.`,
        `We managed to arrive at the beach an hour before sunset. People stacked rocks and made other artistic formations there. The beach was full of that and it was a real photographic paradise. We also went into the water to snorkel a little. However, by now we were too scared to really go crazy for it. I guess Cyprus indeed unlocked a new fear in us - a fear of the deep unknown of the sea.`,
        `When we were biking back home, it was already dark. It was a very silent and warm summer night, with almost no traffic. We could see the moon and the city in the distance. We heard the crickets. It was a really romantic bike ride.`
      ]
    },
    {
      type: 'images',
      value: [
        {
          src: `/images/00_balkans/post26/4.jpg`,
          description: ['photography heaven'],
        },
        {
          src: `/images/00_balkans/post26/5.jpg`,
          description: ['the bright moon'],
        },
      ]
    },
  ],
  signature: `27/10/2023 - 28/10/2023`
}

export default post26
<template>
  <div v-show="post" class="post">
    
    <!-- IMAGE OVERLAY -->
    <transition name="overlay-fade">
      <div v-if="showOverlay" class="overlay" @click="closeOverlay">
        <div class="overlay-content">
          <img :src="selectedImageSrc" class="overlay-image">
          <div class="overlay-description">
            <div class="overlay-description-text">
              <div>{{ selectedImageDesc[0] }}</div>
              <div v-if="selectedImageDesc[1]">~</div>
              <div>{{ selectedImageDesc[1] }}</div>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <!-- MODAL OVERLAY -->
    <ModalNavigation :isVisible="showModalNavigation" @close="showModalNavigation=false" />

    <!-- MENU -->
    <div class="title-wrapper" :class="{ 'blur': showModalNavigation }">
      <div class="background-image">
        <img :src="titleImageUrl" alt="Background" />
      </div>
      <div class="title">
        <div class="title-text">andrea goes...</div>
        <div class="navigation-buttons">
          <button :class="['custom-button button-map', colorClass]" @click="openModalNavigation('map')">Map</button>
          <button :class="['custom-button button-about', colorClass]" @click="openModalNavigation('about')">About</button>
        </div>
      </div>

      <div class="journey-block">
        <div class="journey-title-text">{{ title }}</div>
      </div>
    </div>

    <div class="subtitle" :class="{ 'blur': showModalNavigation }">
      <hr class="subtitle-divider">
      <div class="subtitle-text">blog</div>
      <hr class="subtitle-divider">
    </div>

    <!-- HEADER -->
    <transition :name="postTransName" mode="out-in" class="content-transition">
      <div :key="post.key">
        <div :class="['post-header', { 'blur': showModalNavigation, ...colorClass }]" @click="openModalNavigation('map')">
          <div class="circle-container">
            <div class="circle invisible">
              <div class="circle filled">
                <div class="circle-number">{{ post.key + 1 }}</div>
              </div>
            </div>
          </div>
          <div class="post-location">{{ post.location }}:</div>
          <div class="post-title">
            <span>{{ firstHalf + ' ' }}</span>
            <span>{{ secondHalf }}</span>
          </div>
        </div>

        <!-- CONTENT -->
        <div v-for="(block, index) in post.content" :key="index" class="post-content" :class="{ 'blur': showModalNavigation }">

          <div v-show="shouldDisplayParagraphDivider(index)">
            <div class="paragraph-divider">
              <div class="paragraph-divider-dot"></div>
            </div>
          </div>
          
          <div v-if="block.type === 'text'">
            <p v-for="(text, textIndex) in block.value" :key="textIndex" class="post-paragraph">{{ text }}</p>
          </div>
          
          <div v-else-if="block.type === 'images'">
            <Gallery :images="block.value" @image-click="showImageOverlay" />
          </div>
        </div>

        <!-- SIGNATURE -->
        <p class="post-signature" :class="{ 'blur': showModalNavigation }">{{ post.signature }}</p>
      </div>
    </transition>
  </div>
</template>

<script>
import ModalNavigation from './ModalNavigation.vue'
import Gallery from './Gallery.vue'

export default {
  name: 'Post',
  components: {
    ModalNavigation,
    Gallery
  },
  props: {
    post: Object,
    direction: String
  },
  data () {
    return {
      showModalNavigation: false,
      showBigScreenHeader: true,
      showOverlay: false,
      selectedImageSrc: '',
      selectedImageDesc: [],
    }
  },
  computed: {
    firstHalf () {
      return this.splitTitle(this.post.title).firstHalf
    },
    secondHalf () {
      return this.splitTitle(this.post.title).secondHalf
    },
    journeyIndex () {
      return this.$store.state.selectedJourneyIndex
    },
    title () {
      return this.$store.state.journeys[this.journeyIndex].name
    },
    colorClass () {
      return this.$store.getters.colorClass
    },
    titleImageUrl () {
      const imageName = this.$store.state.journeys[this.journeyIndex].image
      return require(`../backgrounds/${imageName}`)
    },
    postTransName () {
      return this.$store.state.postTransName
    },
    viewMode () {
      return this.$store.state.viewMode
    }
  },
  methods: {
    openModalNavigation (viewMode) {
      if (viewMode === 'about') {
        this.$store.commit('SET_IS_ABOUT', true)
      } else {
        const newView = this.viewMode === 'list' ? 'list' : 'map'
        this.$store.commit('SET_VIEW_MODE', newView)
      }
      this.showModalNavigation = true
    },
    showImageOverlay (image) {
      this.selectedImageSrc = image.src
      this.selectedImageDesc = image.description
      this.showOverlay = true
    },
    closeOverlay () {
      this.showOverlay = false
    },
    splitTitle (title) {
      const words = title.split(' ')
      const halfIndex = Math.ceil(words.length / 2)
      const firstHalf = words.slice(0, halfIndex).join(' ')
      const secondHalf = words.slice(halfIndex).join(' ')
      return { firstHalf, secondHalf }
    },
    handleResize () {
      this.showBigScreenHeader = window.innerWidth > 800
    },
    shouldDisplayParagraphDivider (index) {
      if (index === 0) return false
      const currentBlock = this.post.content[index]
      const previousBlock = this.post.content[index - 1]
      return currentBlock.type === 'text' && previousBlock.type === 'text'
    }
  },
  created () {
    this.$store.commit('SET_SELECTED_POST_INDEX', this.post.key)
    this.$store.commit('SET_SELECTED_JOURNEY_INDEX', this.post.journeyKey)
    this.$store.commit('SET_POSTS', this.post.journeyKey)
    this.handleResize()
  },
  mounted () {
    window.addEventListener('resize', this.handleResize)
  },
  destroyed () {
    window.removeEventListener('resize', this.handleResize)
  },
}
</script>

<style scoped lang="scss">
@import '../main.scss';

.post {
  padding-bottom: 10px;
  background-color: $black;
  overflow: hidden;
  box-shadow: 0px 0px 20px 10px rgba(0,0,0,0.5);
}

.title-wrapper,
.subtitle,
.post-header,
.post-header-small,
.post-content,
.post-signature {
  filter: blur(0px);
  transition: filter 1s ease;
}

.blur {
  filter: blur(3px);
}

.title,
.post-paragraph,
.post-signature {
  padding-left: 12%;
  padding-right: 12%;
}

.post-header,
.post-header-small {
  margin-left: 12%;
  margin-right: 12%;
}

.subtitle {
  padding-left: 20px;
  padding-right: 20px;
}

/*  title  */
.title {
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'RozhaOne';
  color: white;
  padding: 10px 20px;
  height: 35px;

  @media only screen and (max-width: 1000px) {
    margin-bottom: 40px;
  }
  @media only screen and (max-width: 500px) {
    margin-bottom: 30px;
  }

  & .title-text,
  & .navigation-buttons > .custom-button {
    animation: titleAppearFromTop 0.6s ease-in-out forwards;
  }
  
  & .title-text {
    animation-delay: 200ms;
  }

  & .navigation-buttons > .button-map {
    animation-delay: 400ms;
  }

  &  .navigation-buttons > .navigation-icon {
    animation-delay: 400ms;
  }

  & .navigation-buttons > .button-about {
    animation-delay: 600ms;
  }
}

@keyframes titleAppearFromTop {
  0% {
    transform: translateY(-55px);
  }
  100% {
    transform: translateY(0px);
  }
}

.title-text {
  font-size: 25px;
  transform: translateY(-55px);

  @media only screen and (max-width: 1000px) {
    font-size: 20px;
  }
  @media only screen and (max-width: 500px) {
    font-size: 15px;
  }
}

.navigation-buttons {
  display: flex;
  gap: 10px;
  cursor: pointer;

  @media only screen and (max-width: 500px) {
    gap: 5px;
  }
}

.custom-button {
  background-color: transparent;
  color: white;
  border: 2px solid white;
  border-radius: 15px;
  padding: 5px 5px;
  cursor: pointer;
  font-family: 'RozhaOne';
  font-size: 15px;
  width: 100px;
  height: 35x;
  text-align: center;
  transition: all 0.6s ease;
  transform: translateY(-55px);

  &:hover {
    &.green { color: $green-light; border: 2px solid $green-light; }
    &.blue { color: $blue-light; border: 2px solid $blue-light; }
    transition: color 0.6s ease, border 0.6 ease;
  }

  &:active {
    &.green { color: $green-light; border: 2px solid $green-light; }
    &.blue { color: $blue-light; border: 2px solid $blue-light; }
    transition: color 0.6s ease, border 0.6 ease;
  }

  @media only screen and (max-width: 1000px) {
    width: 80px;
  }

  @media only screen and (max-width: 500px) {
    font-size: 14px;
    width: 60px;
    padding: 5px 5px;
  }
}

.navigation-icon {
  opacity: 1;
  width: 40px;
  height: 30px;
  transition: opacity 0.3s ease;
}

.navigation-icon:hover {
  opacity: 0.7;
}

.title-wrapper {
  position: relative;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.background-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.journey-title-text {
  height: 400px;
  display: flex;
  align-items: center;
  font-family: 'RozhaOne';
  color: white;
  font-size: 90px;
  text-align: center;
  justify-content: center;
  transform: translateY(-20px);
  animation: journeyTitleAppearFromTop 0.6s ease-in-out forwards;
  animation-delay: 200ms;

  @media only screen and (max-width: 1000px) {
    font-size: 50px;
  }
  @media only screen and (max-width: 500px) {
    font-size: 30px;
  }
}

@keyframes journeyTitleAppearFromTop {
  0% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
}

.navigation-button {
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
}


/*  subtitle  */
.subtitle {
  display: flex;
  align-items: center;
  font-family: 'RozhaOne';
  color: white;
  margin-top: 10px;
  margin-bottom: 50px;

  @media only screen and (max-width: 1000px) {
    margin-bottom: 40px;
  }
  @media only screen and (max-width: 500px) {
    margin-bottom: 30px;
  }
}

.subtitle-text {
  font-size: 25px;
  margin-right: 20px;
  margin-left: 20px;

  @media only screen and (max-width: 1000px) {
    font-size: 20px;
  }
  @media only screen and (max-width: 500px) {
    font-size: 15px;
    margin-right: 10px;
  }
}

.subtitle-divider {
  height: 100%;
  flex-grow: 1;
  border: 1px solid white;
}

.post-header {
  display: inline-flex;
  align-items: center;
  text-transform: uppercase;
  font-family: 'HedvigLetters';
  margin-bottom: 30px;
  color: $cappuccino;
  cursor: pointer;
  transition: color 0.6s ease;
  flex-wrap: wrap;

  &:hover {
    color: $cappuccino-dark;
  }

  .post-title > span {
    display: block;
  }

  @media only screen and (max-width: 800px) {
    .circle, .circle-container {
      margin-right: 10px;
      width: 25px;
      height: 25px;
    }

    .circle-number {
      font-size: 12px;
    }

    .post-location {
      font-size: 22px;
      overflow-wrap: anywhere;
    }

    .post-title {
      font-size: 14px;
      
      & > span {
        display: inline;
      }
    }
  }
}

// circle
.circle-container {
  position: relative;
  width: $circle-size;
  height: $circle-size;
  margin-right: 20px;
}

.circle {
  cursor: pointer;
  position: absolute;
  width: $circle-size;
  height: $circle-size;
  border-radius: 50%;
  transition: box-shadow 0.6s ease, background-color 0.6s ease;
}

.invisible {
  box-shadow: 0 0 0px $cappuccino;
  background-color: transparent;
  transition: box-shadow 0.6s ease;
}

.filled {
  background-color: $cappuccino;
}

.circle-number {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: $black;
  font-family: 'HedvigLetters';
  font-size: 20px;
  font-weight: bold;
  transition: color 0.6s ease;
}

.post-header:hover .circle {
  background-color: $cappuccino-dark;
  transition: background-color 0.6s ease;
}

.post-header:hover .invisible {
  box-shadow: 0 0 18px $green-light;
  transition: box-shadow 0.6s ease;
}
.post-header.blue:hover .invisible {
  box-shadow: 0 0 18px $blue-light;
}

.post-header:hover .circle-number {
  color: $green-light;
  transition: color 0.6s ease;
}
.post-header.blue:hover .circle-number {
  color: $blue-light;
}

// post title
.post-title,
.post-signature {
  font-weight: 900;
  font-size: 16px;
}

.post-signature {
  margin: 50px 0;
  font-size: 20px;
  color: $cappuccino;
}

.post-location {
  font-size: 40px;
  font-weight: bold;
  margin-right: 15px;
  cursor: pointer;
}

// divider
.paragraph-divider {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 10px 0;
}

.paragraph-divider-dot {
  width: 8px;
  height: 8px;
  background-color: $cappuccino;
  border-radius: 50%;
}

.post-paragraph {
  color: $white;
  text-align: justify;
  font-family: 'Lora';
  font-size: 16px;

  @media only screen and (max-width: 1000px) {
    font-size: 14px;
  }

  @media only screen and (max-width: 500px) {
    font-size: 14px;
  }
}

// overlay
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease;
  opacity: 1;
}

.overlay-content {
  height: 95%;
  transform: scale(1);

  @media only screen and (max-width: 500px) {
    height: unset;
    width: 100%;
  }
}

.overlay-image {
  cursor: pointer;
  object-fit: cover;
  aspect-ratio: 3 / 4;
  overflow: hidden;
  border-radius: 10px;
  width: 100%;
  height: 100%;
}

.overlay-description {
  position: absolute;
  display: flex;
  bottom: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px 0;
}

.overlay-description-text {
  width: 80%;
  color: white;
  margin: auto;
  text-align: center;
  font-family: 'RozhaOne';
  font-size: 1.3vw;

  @media only screen and (max-width: 1000px) {
    font-size: 1.7vw;
  }
  @media only screen and (max-width: 500px) {
    font-size: 3vw;
  }
}

/* transitions */

.content-transition {
  overflow: hidden;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: transform 0.3s ease, opacity 0.2s ease;
}

.slide-fade-enter-from {
  transform: translateX(-10%);
  opacity: 0;
}

.slide-fade-enter-to {
  transform: translateX(0%);
  opacity: 1;
}

.slide-fade-leave-from {
  transform: translateX(0%);
  opacity: 1;
}

.slide-fade-leave-to {
  transform: translateX(+10%);
  opacity: 0;
}

.slide-fade-right-enter-active,
.slide-fade-right-leave-active {
  transition: transform 0.3s ease, opacity 0.2s ease;
}

.slide-fade-right-enter-from {
  transform: translateX(+10%);
  opacity: 0;
}

.slide-fade-right-enter-to {
  transform: translateX(0%);
  opacity: 1;
}

.slide-fade-right-leave-from {
  transform: translateX(0%);
  opacity: 1;
}

.slide-fade-right-leave-to {
  transform: translateX(-10%);
  opacity: 0;
}

.overlay-fade-enter-active,
.overlay-fade-leave-active {
  transition: opacity 0.5s ease;
}

.overlay-fade-enter-active .overlay-image,
.overlay-fade-leave-active .overlay-image {
  transition: transform 0.5s ease;
}

.overlay-fade-enter-from,
.overlay-fade-leave-to {
  opacity: 0;
}

.overlay-fade-enter-from .overlay-image,
.overlay-fade-leave-to .overlay-image {
  transform: scale(0.9);
}

.overlay-fade-enter-to,
.overlay-fade-leave-from {
  opacity: 1;
}

.overlay-fade-enter-to .overlay-image,
.overlay-fade-leave-from .overlay-image {
  transform: scale(1);
}

</style>


import { PostProps } from "@/types"

const post14: PostProps = {
  key: 14,
  journeyKey: 1,
  x: '26%',
  y: '18%',
  zIndex: 110,
  location: '4000 Islands',
  title: 'Magical time on the islands and a meet up written by destiny',
  content: [ 
    {
      type: 'text',
      value: [
        `The 4000 Islands is slowly becoming my favourite spot so far. It is called 4000 islands since it really does look like 4000 islands! It is a lot of scattered land inside the Mekong river delta. The biggest islands are Don Det and Don Khong. Don Det provides more hostels, bungalows and restaurants for the tourists, Don Khong is bigger, has more nature and feels more local. I am staying on Don Khong and whenever necessary, I can go over the bridge to get to Don Det. The other islands would have to be reached by boat, so these two islands are the main thing in the delta.`,
        `I am making my time on the islands a personal retreat. I have a bungalow just for myself. I plan to address my back pain. Do yoga, meditate, read a book in my hammock, eat healthy food and write my diary. A real traveler’s recovery.`,
        `Today, I take my bike and go around my island. I am looking around at the river, the houses and farms on the islands, the kind gestures of locals, and I am feeling euphoric. I find a little waterfall, which is also a place where people go for a swim. I need to cross a bridge that is missing many wood planks and seems like it is just about to collapse. At the other end, I find an abandoned restaurant and a few bikes parked there. I am not the only one who crossed this bridge apparently. I park my bike here, realising how much I love that bicycles are the preferred way of transport here. There are no cars and motorbikes are rare.`,
        `The waterfall is very subtle; it is mainly just water flowing a bit faster over a rocky area. I meet some people here, who also stopped by to cool down a little. Or so I thought. But the water is surprisingly warm! I do not hang around too long.`,
        `Now I am on my bike again, cycling south towards the old french port and an abandoned viewpoint. Here, I look around, and read some information boards about the port. I do not learn a lot since a local lady sends me away - they are cutting a tree in their garden and there is a risk it might fall in my direction.`,
        `I sit in a restaurant, enjoy my lunch and watch the tree fall safely. I observe some local women having an argument. I turn to my little notebook and write my diary - again - a lot of euphoric notes and realisations. The view from this restaurant over the Mekong river is insane.`,
        `I return home via a different route, exploring the less inhabited part of the island. I make sure to exercise my back and then prepare for dinner.`,
        `I find a wonderful Indian restaurant and cannot wait for my curry. I am calling back home and updating with my close ones. While on the call, a beautiful woman asks whether she can join my table. I focus on the call, yet I cannot help but notice her strong presence and openness. She sometimes looks in my direction. She seems like she wants to talk or is inviting me to approach her later if I choose to. I cut my call a little shorter than usual. I am  curious about her.`,
        `Her name is Claudia and she is from Italy. My curry is now on my table and we are enjoying a small conversation. Soon I am finished with my food and Claudia’s food now arrives. I am not sure what she prefers, but I let her eat in peace and leave the restaurant. Not every social interaction needs to be prolonged or forced into an exchange of contacts, I think.`,
        `The bike back home feels extremely romantic, with the moon illuminating my way, the sound of crickets and the dark silhouettes of palm trees waving slowly in a soft breeze. Every now and then, I hear locals blasting their traditional tropical beats at their parties.`
      ]
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/01_southeast_asia/post14/1.jpg',
          description: ['hammock at the bungalow'],
        },
        {
          src: '/images/01_southeast_asia/post14/2.jpg',
          description: ['the bridge crossing'],
        },
        {
          src: '/images/01_southeast_asia/post14/3.jpg',
          description: ['zen diary time'],
        },
        {
          src: '/images/01_southeast_asia/post14/4.jpg',
          description: ['the old french port'],
        },
      ],
    },
    {
      type: 'text',
      value: [
        `On Saturday, I am take a peaceful, slow morning. I enjoy that my host has a restaurant next door. I can literally walk there barefoot and ask for a smoothie. They even encourage me to take it home with me and return the glass later. I read in my hammock, before it gets too hot. After lunch, I bike to a nice beach. Here, the river is calm, allowing me to swim freely. The coastline is rocky, and the giant boulders are great for climbing and exploring. I come down and meet a guy who tells me he took pictures of me while I chilled on the big rocks. “Of course for you, not for me!” We exchange emails so he can send me the pictures.`,
        `This French guy is nice and respectful. When it gets a bit darker, we make sure to bike home. He bikes with me, but I tell him we have arrived a bit earlier before we are actually at my place. Regular safety measures.`,
        `To my surprise, I meet Claudia on the bike and wave to her.`
      ]
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/01_southeast_asia/post14/5.jpg',
          description: ['Don Det - the busier island'],
        },
        {
          src: '/images/01_southeast_asia/post14/6.jpg',
          description: ['sunset in the river'],
        },
        {
          src: '/images/01_southeast_asia/post14/7.jpg',
          description: ['at the beach'],
        },
        {
          src: '/images/01_southeast_asia/post14/8.jpg',
          description: ['reading time'],
        },
      ],
    },
    {
      type: 'text',
      value: [
        `On Sunday morning, I plan to bike to the other island for breakfast. My Island is indeed better for exploring nature, however, restaurants around here feel a little vacated - and it gives me the feeling I am the only person in the world. That is not the case for Don Det.`,
        `First, I find a wonderful place for coffee - I can sit in a comfortable chair right over the river and observe it. I am reading my book and listening to music. The place is called Dondet Coffee House and Gift-shop.`,
        `Later, I move to Mama Tanon Restaurant. This place is full of cute positive quotes and messages. It seems to be full of tourists. This is probably a favorite spot. I can order from plenty of super healthy vegetarian and vegan options. The staff is also super kind.`,
        `While taking out some cash, I bump into Claudia once more. We start finding it a bit funny. It is a small island, but still, quite the coincidence!`,
        `Back home, I want to get some work done on my laptop, but I am shocked to see it is completely discharged and the charger is not working. I am hoping as much as I can that it is just overheated. I decide to give it some time before I start panicking.`,
        `I get ready and head to the Phapheng waterfalls. I am thinking that if I set my mind to it, perhaps, my laptop will be okay. I am trying to stay positive but my mind is fighting back. I have no idea how I could try to fix my laptop in the middle of this wilderness. Leaving the place too early would be sad. And even if I left, where would they be able to fix my laptop? Is the problem with the laptop or the charger? Where can I even find a charger for my laptop? It is such a specific charger that even in Slovakia, it caused me a lot of trouble finding it.`,
        `The waterfalls aggressively throw these thoughts out of my mind. I am gasping. This must be the prettiest place on the whole 4000 Islands! I reach into my bag but find out I forgot my phone. I make peace with that too. It just means I have no pictures of this gorgeousness.`,
        `I take a big walk around the area. Swimming is not really possible; there is only one part of the river that is safely separated from the gushing water and rocks. It is soft and safe, but the water is very shallow and I end up finding quite a lot of trash in it - which discourages me.`,
        `I walk in the other direction, as far as I can and discover a large abandoned beach. I can see there used to be bungalows under construction, along with public toilets. But the river is so aggressively strong here, it would probably not be safe to encourage the public to stay around (let alone to even try entering the water).`,
        `I look at the sand and drop my jaw. It looks like there are millions of tiny gold particles. Everywhere I look, the sand is super shimmery. It is so beautiful. I am walking around the beach and laughing like an insane person. I have seen people panning for gold on the shores of the river, but I would not expect I would get to see so many golden particles myself.`,
        `Cycling back home, I am having my full focus on the laptop again - “It’s going to work, it’s going to work”...`,
        `But it does not.`,
        `I need to find a solution now. There is an electronics shop on the mainland. If I leave the house now, I could still make it. I bike as quickly as I can to the upper side of Don Det, to the port. I buy a boat ticket, make it to the other side and sit in a tuktuk.`,
        `I barely make it before the closing time. At the store, we use google translate to communicate. The shop owner is willing to help, but unable to.`,
        `I take a boat back, hop on my bike, and go to a transportation company. I buy a ticket to Siem Reap - Cambodia, for tomorrow. It makes no sense to move to any city in Laos right now, I am already as south as I can be. It makes the most sense to continue my journey toward Cambodia, to my first intended stop - Siem Reap. Tomorrow is already a work day and I have no time to spare. Even though I planned to stay for a few more days, I have to leave tomorrow. I have a little bit of hope that if I try really hard, I could have my laptop fixed in a few days in Siem Reap.`
      ]
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/01_southeast_asia/post14/9.jpg',
          description: ['cute little positive messages at Mama Tanon'],
        },
        {
          src: '/images/01_southeast_asia/post14/10.jpg',
          description: ['sunset at the boat ride for the unsuccessful attempt to fix my laptop'],
        },
      ],
    },
    {
      type: 'text',
      value: [
        `In the morning, I pack my bags and say goodbye to my beautiful bungalow, my hammock, my bicycle, and this lovely paradise. It is bittersweet. I hope that me leaving this place prematurely has a meaning - and that I will come back some years later.`,
        `I pick up a banana-coffee smoothie that everyone recommended and deeply regret this choice. Soon enough, the boat is at the port next to my bungalow. `,
        `We are brought to the mainland, where we are put into a taxi and brought to the border. Here, the driver drops us off and we understand that we need to cross the border on our own. Perhaps there is another driver on the other side waiting for us.`,
        `The guys at the border control are really funny and they joke around with the whole group. The second control is a long walk that we all need to take part in. We cross the border and now we are in Cambodia!`,
        `We are encouraged to wait at a restaurant/grocery store family business. Every now and then, a new group arrives, some people going to Siem Reap, others to Phnom Penh (the capital) or even other places.`,
        `Believe it or not, one of the new arrivals is… Claudia! We are surprised to see each other once again. I am starting to think this is fate. I know Claudia was supposed to leave the islands on another day, and so was I. Yet here is the woman I keep bumping into. We sit together and I show her the hostel where I will be staying - the Twitz hostel. It is the most gorgeous hostel I have seen and Claudia is completely taken aback. She quickly cancels her booked stay and books one at this hostel too.`,
        `After about two hours of waiting, we are finally put into a van. The ride is quite funny, the driver takes the middle of the road, so he can smoothly overtake any other vehicles. Additionally, he honks about every 10 seconds, so that others know he has claimed the road. It is the orange dusty kind of road. So far, Cambodia seems underdeveloped.`,
        `In the evening, we finally arrive at Siem Reap. This town is the opposite of underdeveloped. The traffic is calm, the roads are big, there are big hotels, restaurants. There are many lights illuminating the streets. It looks so cozy and peaceful.`,
        `Claudia and I have to argue with a tuktuk driver who wants to take us to our hostel. He first makes us sit in his tuktuk convincing us that it is still included in today’s transport, just to find out he does want money for it. We choose to walk but he pulls out all the convincing techniques he has. It is a very uncomfortable situation. Later I learn that this is the style in Cambodia - a fight for the tourist money, whether it is respectful or not.`,
        `After checking in into the most princess-like hostel I have seen, Claudia and I go to the market for dinner. We somehow dive into deep talks about travelling. Claudia can formulate things that have been on the tip of my mind but I was quite missing the last puzzle piece into having these realisations. Sometimes, I get goosebumps from our conversation. It seems like I truly have something to learn from her, and hopefully, vice versa. Yes, I did leave 4000 islands way earlier than I planned, but it does start making sense now. Always bumping into Claudia starts to make sense now.`
      ]
    },
  ],
  signature: '22/03/2024 - 25/03/2024'
}

export default post14

import post0 from '@/posts/01_southeast_asia/00_bangkok_1'
import post1 from '@/posts/01_southeast_asia/01_bangkok_2'
import post2 from '@/posts/01_southeast_asia/02_bangkok_3'
import post3 from '@/posts/01_southeast_asia/03_chiang_mai_1'
import post4 from '@/posts/01_southeast_asia/04_chiang_mai_2'
import post5 from '@/posts/01_southeast_asia/05_chiang_mai_3'
import post6 from '@/posts/01_southeast_asia/06_pai'
import post7 from '@/posts/01_southeast_asia/07_chiang_rai'
import post8 from '@/posts/01_southeast_asia/08_mekong'
import post9 from '@/posts/01_southeast_asia/09_luang_prabang_1'
import post10 from '@/posts/01_southeast_asia/10_luang_prabang_2'
import post11 from '@/posts/01_southeast_asia/11_vang_vieng_1'
import post12 from '@/posts/01_southeast_asia/12_vang_vieng_2'
import post13 from '@/posts/01_southeast_asia/13_vientiane'
import post14 from '@/posts/01_southeast_asia/14_4000_islands'
import post15 from '@/posts/01_southeast_asia/15_siem_reap_1'
import post16 from '@/posts/01_southeast_asia/16_siem_reap_2'
import post17 from '@/posts/01_southeast_asia/17_koh_rong_samloem'
import post18 from '@/posts/01_southeast_asia/18_sihanoukville'
import post19 from '@/posts/01_southeast_asia/19_koh_chang'
import post20 from '@/posts/01_southeast_asia/20_chanthaburi'
import post21 from '@/posts/01_southeast_asia/21_bangkok'

const postsSoutheastAsia = [
  { name: 'Bangkok 1', path: 'bangkok-1', data: post0 },
  { name: 'Bangkok 2', path: 'bangkok-2', data: post1 },
  { name: 'Bangkok 3', path: 'bangkok-3', data: post2 },
  { name: 'Chiang Mai 1', path: 'chiang-mai-1', data: post3 },
  { name: 'Chiang Mai 2', path: 'chiang-mai-2', data: post4 },
  { name: 'Chiang Mai 3', path: 'chiang-mai-3', data: post5 },
  { name: 'Pai', path: 'pai', data: post6 },
  { name: 'Chiang Rai', path: 'chiang-rai', data: post7 },
  { name: 'Mekong', path: 'mekong', data: post8 },
  { name: 'Luang Prabang 1', path: 'luang-prabang-1', data: post9 },
  { name: 'Luang Prabang 2', path: 'luang-prabang-2', data: post10 },
  { name: 'Vang Vieng 1', path: 'vang-vieng-1', data: post11 },
  { name: 'Vang Vieng 2', path: 'vang-vieng-2', data: post12 },
  { name: 'Vientiane', path: 'vientiane', data: post13 },
  { name: '4000 Islands', path: '4000-islands', data: post14 },
  { name: 'Siem Reap 1', path: 'siem-reap-1', data: post15 },
  { name: 'Siem Reap 2', path: 'siem-reap-2', data: post16 },
  { name: 'Koh Rong Samloem', path: 'koh-rong-samloem', data: post17 },
  { name: 'Sihanoukville', path: 'sihanoukville', data: post18 },
  { name: 'Koh Chang', path: 'koh-chang', data: post19 },
  { name: 'Chanthaburi', path: 'chanthaburi', data: post20 },
  { name: 'Bangkok', path: 'bangkok', data: post21 },
]

export default postsSoutheastAsia
